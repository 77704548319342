<template>
  <div>
    <div v-if="brandEdit == false">
      <div style="margin: -45px -24px 24px">
        <a-card :bordered="false">
          <DescriptionList>
            <div style="text-align: right" class="table-operator">
              <label class="detailTop-edit">
                <img :src="tenant" class="icon" />&nbsp;&nbsp;
                {{ data.name }}&nbsp;&nbsp;
                <div :class="this.changeImgbg()">
                  {{ this.changeText() }}
                </div>
              </label>
              <a-button type="primary" @click="onEdit">编辑</a-button>
            </div>
            <div style="margin-left: 52px">
              <Description term="品牌规模"
                ><label>{{ data.scale | showScale }}</label>
              </Description>
              <!--            <Description term="经营品类"-->
              <!--            ><label>{{ this.cuisineTypeList(data.cuisineType) }}</label>-->
              <!--            </Description>-->
              <Description term="销售负责人"
                ><label>{{ data.salesPoc }}</label>
              </Description>
              <Description term="品牌主体"
                ><label>{{ data.entity }}</label>
              </Description>
              <Description term="签约档口"
                ><label>{{ data.kitchenName }}</label>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>
      <div class="overflow-scroll">
        <div style="min-width: 1100px">
          <div style="margin-bottom: 24px">
            <a-card :bordered="false">
              <DescriptionList size="large">
                <a-card :bordered="false">
                  <div class="table-operator" style="margin-top: -24px">
                    <label class="listTitle">账单信息</label>
                    <a-form layout="inline" style="text-align: right">
                      <a-form-item label="所属城市">
                        <a-input
                          v-model="queryParamBill.cityName"
                          @pressEnter="billlist(1, 20)"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item label="所属门店">
                        <a-input
                          v-model="queryParamBill.facilityName"
                          @pressEnter="billlist(1, 20)"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item style="text-align: left" label="账户周期">
                        <a-range-picker
                          style="width: 220px"
                          v-model="cycleDateLg"
                          @change="billlist(1, 20)"
                          allowClear
                        />
                      </a-form-item>
                      <!-- <a-button type="primary" @click="billlist(1, 20)"
                        >搜索</a-button
                      > -->
                    </a-form>
                  </div>
                  <a-table
                    rowKey="id"
                    :pagination="paginationfin"
                    :columns="fincolumns"
                    :dataSource="findata"
                    :scroll="{ x: 1500 }"
                    @change="changePageSizefin"
                  >
                    <span slot="serial" slot-scope="text, record, index">
                      {{ index + 1 }}
                    </span>
                    <span slot="cycleStartDateLg" slot-scope="text, record">
                      {{ record.cycleStartDateLg }} —
                      {{ record.cycleEndDateLg }}
                    </span>
                    <span slot="payCycleStartDateLg" slot-scope="text, record">
                      {{ record.payCycleStartDateLg }} —
                      {{ record.payCycleEndDateLg }}
                    </span>
                    <span
                      slot="action"
                      slot-scope="text, record"
                      class="blueColor"
                    >
                      <template>
                        <a @click="handleDetails(record)">查看</a>&nbsp;&nbsp;
                        <a @click="handleEdit(record)">编辑</a>&nbsp;&nbsp;
                        <a @click="handleActive">操作日志</a>
                      </template>
                    </span>
                  </a-table>
                </a-card>
              </DescriptionList>
            </a-card>
          </div>
          <div>
            <a-card :bordered="false">
              <DescriptionList size="large">
                <a-card :bordered="false">
                  <div class="table-operator" style="margin-top: -24px">
                    <label class="listTitle">合同信息</label>
                    <a-form layout="inline" style="text-align: right">
                      <a-form-item label="所属城市">
                        <a-input
                          v-model="queryParamCon.cityName"
                          @pressEnter="contractlist(1, 20)"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item label="厨房品牌">
                        <a-input
                          v-model="queryParamCon.brandName"
                          @pressEnter="contractlist(1, 20)"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <a-form-item label="所属门店">
                        <a-input
                          v-model="queryParamCon.facilityName"
                          @pressEnter="contractlist(1, 20)"
                          placeholder="请输入"
                        />
                      </a-form-item>
                      <!-- <a-button type="primary" @click="contractlist(1, 20)"
                        >搜索</a-button
                      > -->
                    </a-form>
                  </div>
                  <a-table
                    rowKey="id"
                    :pagination="pagination"
                    :columns="contractColumns"
                    :dataSource="contractData"
                    :scroll="{ x: 800 }"
                    @change="changePageSizecontractData"
                  >
                    <span slot="serial" slot-scope="text, record, index">
                      {{ index + 1 }}
                    </span>
                    <span
                      slot="contractCode"
                      slot-scope="text, record"
                      class="blueColor"
                    >
                      <template>
                        <a @click="contractDetail(record)">{{
                          record.contractCode
                        }}</a
                        >&nbsp;&nbsp;
                      </template>
                    </span>
                    <span slot="startDateLg" slot-scope="text, record">
                      {{ record.startDateLg }} — {{ record.endDateLg }}
                    </span>
                    <span
                      slot="action"
                      slot-scope="text, record"
                      class="blueColor"
                    >
                      <template>
                        <a @click="handleDetails(record)">查看</a>&nbsp;&nbsp;
                        <a @click="handleEdit(record)">编辑</a>&nbsp;&nbsp;
                        <a @click="handleActive">操作日志</a>
                      </template>
                    </span>
                  </a-table>
                </a-card>
              </DescriptionList>
            </a-card>
          </div>
        </div>
      </div>
    </div>
    <brandListEdit
      v-if="brandEdit == true"
      :editDetail="editDetail"
      @cancelbrandEdit="cancelbrandEdit"
    ></brandListEdit>
  </div>
</template>

<script>
import moment from "moment";
import tenant from "@/assets/tenant.png";
import brandListEdit from "../brandList/brandListEdit";
import axios from "axios";

import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
  
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";

export default {
  name: "brandListDetail",
  components: {
    DescriptionList,
    Description,
    brandListEdit
  },
  // props: ["thisDetail"],
  data() {
    return {
      thisDetailId: this.$route.query.thisDetailId,
      thisDetailstatus: this.$route.query.thisDetailstatus,
      thisDetailcode: this.$route.query.thisDetailcode,
      tenant,
      brandEdit: false,
      editDetail: "",
      paginationfin: defaultPagination(() => {}),
      pagination: defaultPagination(() => {}),
      data: [],
      queryParamCon: {
        cityName: "",
        facilityName: "",
        brandName: ""
      },
      queryParamBill: {
        cityName: "",
        facilityName: ""
      },
      cycleDateLg: "",
      fincolumns: [
        {
          title: "账单编号",
          dataIndex: "code",
          key: "code",
          fixed: "left"
        },
        {
          title: "客户名称",
          dataIndex: "tenantName",
          key: "tenantName"
        },
        {
          title: "所属城市",
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "所属门店",
          dataIndex: "test",
          key: "test"
        },
        {
          title: "所属档口",
          dataIndex: "kitchenName",
          key: "kitchenName"
        },
        {
          title: "账单周期",
          // colSpan: 2,
          dataIndex: "cycleStartDateLg",
          key: "cycleStartDateLg",
          scopedSlots: { customRender: "cycleStartDateLg" }
        },
        // {
        //   title: "账单周期END",
        //   colSpan: 0,
        //   dataIndex: "cycleEndDateLg",
        //   key: "cycleEndDateLg"
        // },
        {
          title: "账单金额",
          dataIndex: "billAmount",
          key: "billAmount"
        },
        {
          title: "缴纳周期",
          // colSpan: 2,
          dataIndex: "payCycleStartDateLg",
          key: "payCycleStartDateLg",
          scopedSlots: { customRender: "payCycleStartDateLg" }
        },
        // {
        //   title: "缴纳周期END",
        //   colSpan: 0,
        //   dataIndex: "payCycleEndDateLg",
        //   key: "payCycleEndDateLg"
        // },
        {
          title: "滞纳金",
          dataIndex: "mustLateFee",
          key: "mustLateFee"
        },
        {
          title: "需缴费金额",
          dataIndex: "needPaymentAmount",
          key: "needPaymentAmount"
        },
        {
          title: "支付状态",
          dataIndex: "payStatus",
          key: "payStatus"
        },
        {
          title: "支付时间",
          dataIndex: "payDateLg",
          key: "payDateLg"
        },
        {
          title: "是否到账",
          dataIndex: "isArrivalAmount",
          key: "isArrivalAmount",
          fixed: "right",
          width: 110
        }
      ],
      findata: [],
      contractColumns: [
        {
          title: "签订合同编号",
          // dataIndex: "contractCode",
          key: "contractCode",
          scopedSlots: { customRender: "contractCode" },
          fixed: "left"
        },
        {
          title: "合同起止时间",
          // colSpan: 2,
          dataIndex: "startDateLg",
          key: "startDateLg",
          scopedSlots: { customRender: "startDateLg" }
        },
        // {
        //   title: "合同起止时间END",
        //   colSpan: 0,
        //   dataIndex: "endDateLg",
        //   key: "endDateLg"
        // },
        {
          title: "所属城市",
          dataIndex: "cityName",
          key: "cityName"
        },
        {
          title: "厨房品牌",
          dataIndex: "brandName",
          key: "brandName"
        },
        {
          title: "所属门店",
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "客户状态",
          dataIndex: "tenantStatus",
          key: "tenantStatus",
          fixed: "right",
          width: 100
        }
      ],
      contractData: []
    };
  },
  filters: {
    showScale(val) {
      switch (val) {
        case 1:
          return "全国品牌";
        case 2:
          return "区域品牌";
        case 3:
          return "地方品牌";
      }
    }
  },
  created() {
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/brandList"
      });
    });
    this.init();
    this.billlist();
    this.contractlist();
  },
  methods: {
    moment,
    onEdit() {
      // this.editDetail = this.thisDetail;
      this.$router.push({
        path: "/role/brandList/brandListEdit",
        query: {
          editDetailId: this.thisDetailId,
          thisDetailstatus: this.thisDetailstatus,
          thisDetailcode: this.thisDetailcode
        }
      });
    },
    //合同详情
    contractDetail(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: val.id }
      });
    },
    cancelbrandEdit() {
      this.init();
      this.brandEdit = false;
    },
    init() {
      axios({
        url:   this.$baseUrl + "/brand/query",
        method: "GET",
        params: {
          id: this.thisDetailId
        }
      }).then(res => {
        // res.data.cuisineType = this.cuisineTypeList(res.data.cuisineType);
        this.data = res.data.obj;
      });
    },
    //合同信息
    changePageSizecontractData(val) {
      this.contractlist(val.current, val.pageSize);
    },
    contractlist(current, pageSize) {
      axios({
        url:   this.$baseUrl + "contract/kitchen/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParamCon,
          brandId: this.thisDetailId
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.startDateLg = this.changeTimeStyle(item.startDateLg);
            item.endDateLg = this.changeTimeStyle(item.endDateLg);
            this.brandListTenantStatus(item);
          });
          this.contractData = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    changeTimeStyle(time) {
      return time ? moment(time).format("YYYY-MM-DD") : "";
    },
    brandListTenantStatus(val) {
      if (val.tenantStatus == null) {
        return;
      } else {
        switch (val.tenantStatus) {
          case 1:
            val.tenantStatus = "预定中";
            break;
          case 2:
            val.tenantStatus = "已预订";
            break;
          case 3:
            val.tenantStatus = "签约中";
            break;
          case 4:
            val.tenantStatus = "已签约";
            break;
          case 5:
            val.tenantStatus = "改造中";
            break;
          case 6:
            val.tenantStatus = "待入驻";
            break;
          case 7:
            val.tenantStatus = "已入驻";
            break;
          case 8:
            val.tenantStatus = "申请退租中";
            break;
          case 9:
            val.tenantStatus = "退租中";
            break;
          case 10:
            val.tenantStatus = "已退租";
            break;
          case 11:
            val.tenantStatus = "已离场";
            break;
          default:
            "无";
        }
      }
      return val.status;
    },
    //账单信息
    changePageSizefin(val) {
      this.billlist(val.current, val.pageSize);
    },
    billlist(current, pageSize) {
      var start = new Date(this.cycleDateLg[0]);
      axios({
        url:   this.$baseUrl + "/bill/relation/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParamBill,
          brandId: this.thisDetailId,
          cycleStartDateLg: new Date(this.cycleDateLg[0]).getTime() || "",
          cycleEndDateLg: new Date(this.cycleDateLg[1]).getTime() || ""
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            item.cycleStartDateLg = this.changeTimeStyle(item.cycleStartDateLg);
            item.cycleEndDateLg = this.changeTimeStyle(item.cycleEndDateLg);
            item.payCycleStartDateLg = this.changeTimeStyle(
              item.payCycleStartDateLg
            );
            item.payCycleEndDateLg = this.changeTimeStyle(
              item.payCycleEndDateLg
            );
            item.isArrivalAmount =
              item.isArrivalAmount == "Y" ? "已到账" : "未到账";
            item.payStatus =
              item.payStatus == 1
                ? "未支付"
                : [item.payStatus == 2 ? "部分支付" : "已支付"];
          });
          this.findata = res.data.rows;
          const pagination = { ...this.paginationfin };
          pagination.total = res.data.count;
          this.paginationfin = pagination;
        }
      });
    },
    cuisineTypeList(val) {
      // alert(val);
      if (val) {
        switch (val) {
          case 0:
            val = " ";
            break;
          case 1:
            val = "粤菜";
            break;
          case 2:
            val = "川湘菜";
            break;
          case 3:
            val = "江浙菜";
            break;
          case 4:
            val = "快餐便当";
            break;
          case 5:
            val = "简餐沙拉";
            break;
          case 6:
            val = "米粉面馆";
            break;
          case 7:
            val = "饺子馄饨";
            break;
          case 8:
            val = "包子粥店";
            break;
          case 9:
            val = "生煎锅贴";
            break;
          case 10:
            val = "小吃烧烤";
            break;
          case 11:
            val = "香锅干锅";
            break;
          case 12:
            val = "海鲜龙虾";
            break;
          case 13:
            val = "砂锅汤类";
            break;
          case 14:
            val = "火锅";
            break;
          case 15:
            val = "西餐";
            break;
          case 16:
            val = "东南亚菜";
            break;
          case 17:
            val = "日韩菜";
            break;
          case 18:
            val = "意面披萨";
            break;
          case 19:
            val = "汉堡薯条";
            break;
          case 20:
            val = "其他地方菜系";
            break;
          case 21:
            val = "甜品饮品";
            break;
          case 22:
            val = "果蔬生鲜";
            break;
          case 23:
            val = "鲜花绿植";
            break;
          case 24:
            val = "医药健康";
            break;
          case 25:
            val = "商品超市";
            break;
          case 26:
            val = "服装鞋帽";
            break;
          case 27:
            val = "美妆";
            break;
          default:
            val = "无";
        }
      }
      // alert(val);
      return val;
    },
    changeText() {
      if (this.thisDetailstatus == 2) {
        return "未合作";
      } else if (this.thisDetailstatus == 1) {
        return "已合作";
      }
    },
    changeImgbg() {
      if (this.thisDetailstatus == 2) {
        return "close";
      } else if (this.thisDetailstatus == 1) {
        return "open";
      }
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
.ant-form-item {
  vertical-align: initial;
}
</style>
